@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body{
        @apply font-[Open+Sans];
    }
    li {
        @apply px-4; 
        @apply cursor-pointer
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 350px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        #f1ecea,
        #f1ecea
    )!important;    
}